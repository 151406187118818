import React from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from "../components/Common/PageBanner"
import Footer from "../components/App/Footer"
import CaseStudiesDetailsContent from "../components/CaseStudiesDetails/CaseStudiesDetailsContent"
import RelatedProjects from "../components/CaseStudiesDetails/RelatedProjects"

const CaseDetails = () => {
  const pageTitle = "Case Study - Empire Distributions"
  const activePageText = "Case Study Empire Distributions"

  // Main Content Area
  const type =
    "1 Admin Website + 1 Internal Sales Website + 1 Internal iOS & Android Driver App + 1 Backend Database + 1 Backend Server";

const title = "Sales, Inventory Management with maps & expiry dates"
const body1 = "The Challenge - Empire's challenge was to create an innovative product to make their own warehouse operations easier and more detailed with expiry dates, location of each inventory, and a complex sales and order picking system. Price and amounts are audited by a connection to quickbooks where the entire database is mirrored where able. The site that we inherited was never tested and had many many bugs. "
const body2 = "The Solution - We built this solution as a contiuation from a previous developer, so we stayed in the stack: React / Firebase / Node. We cleaned up unnessicary services and added cloud function connections. We moved the enviroment from buggy to stable by building up 1 part at a time, constantly asking for feedback and review."
const body3 = "After 3 months of focused development, we managed to bring the product to a stable state. During the time, we tried to launch 3 times, but at each launch we realized the producted needed more features. By the 4th, going with feedback from the employees and order pickers themselves, we were able to bring this to production."
const results = "The Result - An innovative complex warehouse application that was brought back to life. All products are internal and confidential."


  // Side bar content area
  const clientName = "Empire Distributions Inc."
  const clientLink = "https://empiredistributions.ca"
  const clientName2 = ""
  const clientLink2 = ""
  const location = "Mississauga, ON, Canada"
  const technologies = "Front-end: React Native, React  Back-end: Google Cloud, Firebase"
  const completionDate = "2023 June"
  
  const products = [{ hrefName: "Driver Application" },
                    { hrefName: "Sales Portal" },
                    { hrefName: "Admin Portal" },
                  ]

  return (
    <Layout pageTitle={pageTitle}>
      <Navbar />
      <PageBanner
        pageTitle={pageTitle}
        homePageText="Home"
        homePageUrl="/"
        activePageText={activePageText}
      />
      <CaseStudiesDetailsContent
        type={type}
        title={title}
        body1={body1}
        body2={body2}
        body3={body3}
        results={results}
        clientName={clientName}
        clientLink={clientLink}
        clientName2={clientName2}
        clientLink2={clientLink2}
        location={location}
        technologies={technologies}
        completionDate={completionDate}
        products={products}
      />
      {/* <RelatedProjects /> */}
      <Footer />
    </Layout>
  )
}

export default CaseDetails
